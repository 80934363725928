import React from "react";
import "./countdown.scss";

class Countdown extends React.PureComponent {

  state = {
    secondsRemaining: this.props.secondsRemaining || 10
  }

  tick = () => {
    this.setState({ secondsRemaining: this.state.secondsRemaining - 1 });
    if (this.state.secondsRemaining <= 0) {
      clearInterval(this.interval);
      if (this.props.countdownDone) {
        this.props.countdownDone();
      }
    }
  };

  startCountdown() {
    this.setState({ secondsRemaining: this.props.secondsRemaining || 10 });
    this.interval = setInterval(this.tick, 1000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.beginCountdown !== this.props.beginCountdown) {
      if (this.props.beginCountdown) {
        this.startCountdown();
      }
    }
  }

  componentDidMount() {
    if (this.props.beginCountdown) {
      this.startCountdown();
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="countdown color--purple-dark">
        {this.state.secondsRemaining}
      </div>
    )
  }
}

export default Countdown
