import React from "react"
import Seesaw from "components/seesaw";
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "components/seo"
import TiltPhone from "images/tilt.inline.svg";
import Onboarding from "images/onboarding.inline.svg";
import Rodal from 'rodal';
import { navigate } from '@reach/router';
import { withPrefix } from "gatsby";

import "./game.scss";
import 'rodal/lib/rodal.css';

class Game extends React.PureComponent {
    constructor(props) {
        super(props);
        if (typeof window !== 'undefined') {
            this.mql = window.matchMedia("(orientation: landscape)");
        }
    }

    state = {
        onboardingModalVisible: false,
        onboardingClosedByUser: false,
        startGame: false,
        failModalVisible: false,
        isFacebookOrInstagramApp: false,
        copied: false
    }

    isFacebookApp() {
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
    }

    isInstagramApp() {
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        var isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;
        return isInstagram
    }

    startEqualizr = () => {
        this.setState({
            startGame: true,
            onboardingClosedByUser: true,
            onboardingModalVisible: false
        });
    }

    showFail = () => {
        this.setState({
            failModalVisible: true
        }, () => {
            setTimeout(() => {
                this.setState({ failModalVisible: false }, () => {
                    navigate(withPrefix(`/${this.props.intl.locale}/take-action`));
                })
            }, 1500);
        });
    }

    copyToClipboard = () => {
        var textField = document.createElement('textarea')
        textField.innerText = window.location.href;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()

        this.setState({
            copied: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    copied: false
                })
            }, 1500);
        })
    }

    componentDidMount() {

        if (this.isFacebookApp() || this.isInstagramApp()) {
            this.setState({
                isFacebookOrInstagramApp: true
            })
        }

        if (this.mql.matches) {
            this.setState({
                onboardingModalVisible: true
            });
        }

        this.mql.addListener(m => {
            if (m.matches) {
                if (!this.state.onboardingClosedByUser) {
                    this.setState({
                        onboardingModalVisible: true
                    });
                }
            } else {
                if (this.state.onboardingModalVisible) {
                    this.setState({
                        onboardingModalVisible: false
                    });
                }
            }
        });
    }

    render() {
        const { intl } = this.props;

        return (
            <div className="game-container">
                <SEO title={intl.formatMessage({ id: "game-page.page-title" })} description={intl.formatMessage({ id: "game-page.page-description" })} />
                <div className="game">
                    <div className="game__copy">
                        <h1 className="fs--lg color--yellow" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "game-page.title" }) }}></h1>
                        <h3 className="fs--xs color--yellow" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "game-page.subtitle" }) }}></h3>
                    </div>
                    <Seesaw showFail={this.showFail} startGame={this.state.startGame} />
                </div>
                <div className="tilt-phone">
                    {!this.state.isFacebookOrInstagramApp &&
                        <span className="color--white">{intl.formatMessage({ id: "game-page.tilt-message" })}</span>
                    }
                    {this.state.isFacebookOrInstagramApp &&
                        <span className="color--white">Unfortunately you can not tilt your phone within facebooks or instagrams app.
                        <br />
                            <br />
                            Please open the link in a browser
                            <br />
                            <br />
                            <button onClick={this.copyToClipboard}>Click here to copy link to clipboard</button>
                        </span>
                    }
                    <TiltPhone className="tilt-phone-icon" />
                    <svg className="tilt-oxfam-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 50">
                        <g fill="#FFF" fillRule="evenodd">
                            <path d="M24.95 17.855a6.336 6.336 0 0 0 3.225-5.075.157.157 0 0 0-.157-.157h-2.774c-.086 0-.146.069-.156.157-.225 1.55-1.539 2.738-3.126 2.738s-2.9-1.189-3.126-2.738c-.008-.092-.07-.157-.156-.157h-2.775a.157.157 0 0 0-.155.157 6.335 6.335 0 0 0 3.223 5.075 6.34 6.34 0 0 0-3.239 5.394c0 .088.07.159.156.159h2.759c.086 0 .156-.07.156-.159.081-1.7 1.464-3.053 3.157-3.053 1.694 0 3.077 1.353 3.157 3.053 0 .088.07.159.156.159h2.76c.086 0 .155-.07.155-.159a6.34 6.34 0 0 0-3.24-5.394zm-9.04-5.866h2.775c.086 0 .147-.07.155-.159.226-1.548 1.54-2.737 3.127-2.737 1.586 0 2.9 1.19 3.126 2.737.01.088.08.159.165.159h2.765c.086 0 .156-.07.156-.158-.238-3.275-2.929-5.856-6.212-5.856-3.284 0-5.974 2.581-6.212 5.856 0 .088.069.158.155.158zm21.265 3.465c0 8.535-6.81 15.455-15.208 15.455-8.399 0-15.208-6.92-15.208-15.455S13.568 0 21.967 0c8.399 0 15.208 6.92 15.208 15.454zM3.893 37.557C1.235 37.565 0 39.46 0 41.067v5.44c0 1.61 1.235 3.505 3.893 3.512 2.658-.007 3.893-1.902 3.893-3.511v-5.44c0-1.609-1.235-3.503-3.893-3.51zm1.488 8.764c0 .93-.938 1.244-1.488 1.244s-1.488-.315-1.488-1.244v-5.065c0-.93.938-1.245 1.488-1.245s1.488.315 1.488 1.245v5.065zM20.953 49.797V45.06h3.592v-2.428H20.97V40.22h4.162v-2.443h-6.578v12.019h2.4M31.306 49.797h2.397l-3.135-12.02h-2.805l-3.086 12.02h2.41l.55-2.13h3.13l.539 2.13zm-2.1-8.268l.96 3.782h-1.94l.98-3.782zM14.265 43.779l3.062-6.001h-2.778l-1.676 3.5-1.675-3.5H8.42l3.062 6.001-3.079 6.018h2.81l1.66-3.491 1.66 3.49h2.81l-3.078-6.017M41.437 37.778l-2.117 4.624-2.145-4.624h-2.463v12.02h2.35V42.96l1.51 3.197h1.501l1.51-3.187v6.827h2.351v-12.02h-2.497" />
                        </g>
                    </svg>
                </div>
                <Rodal className="onboarding-modal" customStyles={{ width: "100%", height: "auto" }} showCloseButton={false} closeMaskOnClick={false} onClose={() => this.setState({ onboardingModalVisible: false })} visible={this.state.onboardingModalVisible} enterAnimation="fade">
                    <div className="onboarding">
                        <h2 className="color--purple">{intl.formatMessage({ id: "game-page.onboarding.title" })}</h2>
                        <div className="onboarding__content">
                            <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "game-page.onboarding.paragraph" }) }}></p>
                            <Onboarding />
                        </div>
                        <button type="button" onClick={this.startEqualizr}><span>{intl.formatMessage({ id: "game-page.onboarding.go-button" })}</span></button>
                    </div>
                </Rodal>
                <Rodal customMaskStyles={{ background: "rgba(0, 0, 0, .6)" }} customStyles={{ width: "100%", height: "auto" }} className="fail" showCloseButton={false} closeMaskOnClick={false} visible={this.state.failModalVisible} animation="fade" duration={0}>
                    <div className="fail-container">
                        <h2 className="fs--xxl color--yellow">{intl.formatMessage({ id: "game-page.fail" })}</h2>
                    </div>
                </Rodal>
                <Rodal customMaskStyles={{ background: "rgba(0, 0, 0, .6)" }} customStyles={{ width: "100%", height: "auto" }} className="fail" showCloseButton={false} closeMaskOnClick={false} visible={this.state.copied} animation="fade" duration={0}>
                    <div className="fail-container clipboard">
                        <h2 className="fs--xxl color--yellow">Copied!</h2>
                    </div>
                </Rodal>
            </div>
        );
    }
}

export default injectIntl(Game)
