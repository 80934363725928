import React from "react"
import "./seesaw.scss"
import { detectTransitionEndEventName } from "utils/detectEventName";
import Countdown from "components/countdown";
import MoneyBagIcon from "images/money-bag.inline.svg";

let animationStep = 1;

class Seesaw extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            startGame: false,
            seesawDegrees: 0,
            maximumDegree: 0,
            step: 0,
            sawColorOpacity: 0,
            currentStep: -5,
            showHitPoints: false,
            beginCountdown: false,
            firstClick: true
        }

        this.stepCount = 5;
        this.triangleRef = React.createRef();
        this.sawRef = React.createRef();
    }

    /**
     * Calculate maximum degress for .saw(this.sawRef)
     *
     * @memberof Seesaw
     */
    calculateMaximumDegree() {
        return new Promise(resolve => {
            const angleInRadians = Math.asin((this.triangleRef.current.offsetHeight) / (this.sawRef.current.clientWidth / 2))

            const toRadius = angleInRadians * (180 / Math.PI);

            this.setState({
                maximumDegree: toRadius,
                step: (toRadius / this.stepCount)
            }, () => {
                resolve();
            });
        });
    }

    introAnimationDone = () => {
        animationStep = animationStep + 1;

        // if (animationStep === 2) {
        //     this.setState({
        //         seesawDegrees: -this.state.maximumDegree + this.state.step
        //     });
        // }

        // if (animationStep === 2) {
        this.sawRef.current.removeEventListener(detectTransitionEndEventName(), this.introAnimationDone)
        this.setState({
            seesawDegrees: -this.state.maximumDegree,
        }, () => {
            if (!isNaN(this.state.maximumDegree)) {
                this.setState({
                    beginCountdown: true,
                    showHitPoints: true
                });
            }
        });
        // }
    }

    isBetween(a, b) {
        return (this.state.currentStep - a) * (this.state.currentStep - b) <= 0
    }

    startInto() {
        clearInterval(this.interval);

        //Set seesaw to male maximum is a intro.
        setTimeout(() => {
            this.sawRef.current.addEventListener(detectTransitionEndEventName(), this.introAnimationDone);
            this.setState({
                seesawDegrees: -this.state.maximumDegree
            });
        }, 250);
    }

    componentDidUpdate(prevProps) {
        // if ((prevProps.tiltSeesaw !== this.props.tiltSeesaw) && this.props.tiltSeesaw) {
        //     this.calculateMaximumDegree().then(() => {
        //         this.tiltSeesaw();
        //     });
        // }

        if ((prevProps.startGame !== this.props.startGame) && this.props.startGame) {
            this.calculateMaximumDegree().then(() => {
                this.startInto();
            });
        }
    }

    // tiltSeesaw() {
    //     //Set the seesaw to continues tilting
    //     let tiltRight = true;

    //     this.interval = setInterval(() => {
    //         this.setState({
    //             seesawDegrees: tiltRight ? -this.state.step : this.state.step
    //         }, () => {
    //             tiltRight = !tiltRight;
    //         })
    //     }, 600);
    // }

    componentDidMount() {
        if (this.props.startGame) {
            this.startInto();
        }

        window.addEventListener("resize", () => {
            if (this.triangleRef.current && this.sawRef.current && this.props.startGame) {
                this.calculateMaximumDegree().then(() => {
                    if (!isNaN(this.state.maximumDegree)) {
                        this.startInto();
                    }
                });
            }
        });
    }

    seesawAnimationEnd = () => {
        this.sawRef.current.removeEventListener(detectTransitionEndEventName(), this.introAnimationDone);
        this.addPointsToMale();
    }

    addPointsToFemale = () => {
        const femaleSteps = this.state.firstClick ? this.state.step : this.state.step / 26;
        if (this.state.firstClick) {
            this.setState({
                firstClick: false
            })
        }
        if (this.state.showHitPoints) {
            if (this.state.seesawDegrees + femaleSteps <= this.state.maximumDegree) {
                this.setState({
                    seesawDegrees: this.state.seesawDegrees + femaleSteps,
                    currentStep: Math.floor(this.state.seesawDegrees / this.state.step)
                }, () => {
                    this.sawRef.current.addEventListener(detectTransitionEndEventName(), this.seesawAnimationEnd);
                });
            }
        }
    }

    addPointsToMale = () => {
        if (this.state.showHitPoints) {
            // if (this.state.seesawDegrees - this.state.step >= -this.state.maximumDegree) {
            this.setState({
                // seesawDegrees: this.state.seesawDegrees - this.state.step < this.state.maximumDegree ? -this.state.maximumDegree : this.state.seesawDegrees - this.state.step,
                seesawDegrees: -this.state.maximumDegree,
                currentStep: -5,
                firstClick: true
            }, () => {
                if (this.currentStep === -5) {
                    this.sawRef.current.removeEventListener(detectTransitionEndEventName(), this.seesawAnimationEnd);
                }
            });
            // }
        }
    }

    countdownDone = () => {
        this.props.showFail();
    }

    render() {
        return (
            <>
                <div className="game__seesaw seesaw">
                    <div className="seesaw__container">
                        <Countdown beginCountdown={this.state.beginCountdown} secondsRemaining={10} countdownDone={this.countdownDone} />
                        <div className="saw" ref={this.sawRef} style={{ transform: `rotate(${this.state.seesawDegrees}deg)` }}>
                            <div className={`gender gender--male`}>
                                <MoneyBagIcon className="moneyBag1" />
                                <MoneyBagIcon className="moneyBag2" />
                                <svg
                                    className="male-svg"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.8 95.8">
                                    <title>male-icon</title>
                                    <path className="body" d="M88,48.21a40,40,0,1,1-40-40,40,40,0,0,1,40,40Z" />
                                    <path className="eyes" d="M36.57,37.6A3.6,3.6,0,1,1,33,34a3.6,3.6,0,0,1,3.6,3.6m30.6,0a3.6,3.6,0,1,1-3.6-3.6,3.6,3.6,0,0,1,3.6,3.6" />
                                    <path className={`mouth mouth--regular ${this.state.currentStep === 0 ? 'mouth--active' : ''}`} d="M66.55,64.67H28.75" />
                                    <path className={`mouth mouth--semi-sad ${this.isBetween(1, 3) ? 'mouth--active' : ''}`} d="M66.55,64.67c-4.45-2.75-11.26-4.51-18.9-4.51s-14.45,1.76-18.9,4.51" />
                                    <path className={`mouth mouth--sad ${this.isBetween(4, 5) ? 'mouth--active' : ''}`} d="M66.55,64.67a24.29,24.29,0,0,0-37.8,0" />
                                    <path className={`mouth mouth--semi-happy ${this.isBetween(-1, -3) ? 'mouth--active' : ''}`} d="M67.18,57.37c-4.46,2.75-11.27,4.52-18.91,4.52s-14.45-1.77-18.9-4.52" />
                                    <path className={`mouth mouth--happy ${this.isBetween(-4, -5) ? 'mouth--active' : ''}`} d="M67.18,57.37a24.3,24.3,0,0,1-37.81,0" />
                                </svg>
                                <MoneyBagIcon className="moneyBag1" />
                                <MoneyBagIcon className="moneyBag3" />
                            </div>
                            <div className={`gender gender--female`}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.8 95.8">
                                    <title>female-icon</title>
                                    <path className="body" d="M88,48.21a40,40,0,1,1-40-40,40,40,0,0,1,40,40Z" />
                                    <path className="eyes" d="M36.57,37.6A3.6,3.6,0,1,1,33,34a3.6,3.6,0,0,1,3.6,3.6m30.6,0a3.6,3.6,0,1,1-3.6-3.6,3.6,3.6,0,0,1,3.6,3.6" />
                                    <path className={`mouth mouth--regular ${this.state.currentStep === -3 ? 'mouth--active' : ''}`} d="M66.55,64.67H28.75" />
                                    <path className={`mouth mouth--semi-sad ${this.state.currentStep === -4 ? 'mouth--active' : ''}`} d="M66.55,64.67c-4.45-2.75-11.26-4.51-18.9-4.51s-14.45,1.76-18.9,4.51" />
                                    <path className={`mouth mouth--sad ${this.state.currentStep === -5 ? 'mouth--active' : ''}`} d="M66.55,64.67a24.29,24.29,0,0,0-37.8,0" />
                                    <path className={`mouth mouth--semi-happy ${this.isBetween(-1, -2) ? 'mouth--active' : ''}`} d="M67.18,57.37c-4.46,2.75-11.27,4.52-18.91,4.52s-14.45-1.77-18.9-4.52" />
                                    <path className={`mouth mouth--happy ${this.state.currentStep >= 0 ? 'mouth--active' : ''}`} d="M67.18,57.37a24.3,24.3,0,0,1-37.81,0" />
                                </svg>
                            </div>
                            <span className="saw__color saw__color--white"></span>
                        </div>
                        <div className="triangle" ref={this.triangleRef}></div>
                    </div>
                </div>
                <button onClick={this.addPointsToFemale} className="hitPoint hitPoint--right hitPoint--female"></button>
                <button onClick={this.addPointsToMale} className="hitPoint hitPoint--left hitPoint--male"></button>
            </>
        );
    }
}

export default Seesaw
